$baseFontSize: 100%;
$baseIconFontFamily: "Font Awesome 5 Free";
$brandIconFontFamily: "Font Awesome 5 Brands";

/* Color palette */
$grayDarker: #141414;
$grayDark: #222;
$grayMedium: #323232;
$grayLight: #3f3f3f;
$grayLighter: #eee;
$textOnWhite: #252525;
$textContrast: #2d2d2d;
$textOnGray: #fff;
$textSubtle: #5b5b5b;
$textSubtleLight: #adadad;
$blue: #00b5ff;
$blueLight: #39c6ff;
$blueDark: #007fb2;
$green: #00a338;
$warning: #ff460d;
$orange: #ff8700; // "TYPO3 orange"

/* Sizes & margins */
$unit: 40px;
$defaultMargin: 16px;
$relatedMargin: 8px;
$tightMargin: 4px;
$wideMargin: 32px;

/* Components */
$inspectorWidth: $unit * 8;
$navigatePanelWidth: $unit * 8;
$menuWidth: $unit * 8;
$editPreviewPanelHeight: $unit * 2.75;
$menuButtonWidth: 54px;
$generalFontSize: 14px;

$zindexTooltip: 999999;
$errorText: $warning;
$successText: $green;
$warningText: $orange;
$infoText: $blue;
