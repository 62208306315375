@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans"), local("NotoSans"),
    url("../Fonts/NotoSans/NotoSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans Bold"), local("NotoSans-Bold"),
    url("../Fonts/NotoSans/NotoSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Noto Sans Italic"), local("NotoSans-Italic"),
    url("../Fonts/NotoSans/NotoSans-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Noto Sans Bold Italic"), local("NotoSans-BoldItalic"),
    url("../Fonts/NotoSans/NotoSans-BoldItalic.woff2") format("woff2");
}
